.sdc-climate-countdown__warming-text {
  display: inline-block;
  font-family: 'Sky Headline';
  text-align: left;
  margin: 0.2em;
  letter-spacing: 0.05em;
}

.sdc-climate-countdown__warming-value {
  display: inline-block;
  width: 4.9em;
}
