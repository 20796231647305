.sdc-energy-mix__value {
  display: block;
  font-family: 'Sky Headline';
  text-align: center;
}

.sdc-energy-mix__unit {
  display: block;
  font-family: 'Sky Headline';
  text-align: center;
}

.sdc-energy-mix__item {
  padding: 10px;
  border-bottom: 1px solid rgba(202, 202, 207, .3);
}

.sdc-energy-mix__grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  width: 100%;
}

.sdc-energy-mix__contents  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width:  26.8125rem;
  width: 100%;
  height: 22.5625rem;
  margin: 0 auto;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.sdc-energy-mix__glass-effect {
  position: absolute;
  width: 100%;
  height: 12rem;
  top: -5px;
  left: 0px;

  background: linear-gradient(347.32deg, rgba(255, 255, 255, 0.25) 30.64%, rgba(255, 255, 255, 0) 78.74%);
  mix-blend-mode: overlay;
  opacity: 0.6;
  transform: skewY(-2.5deg);
}