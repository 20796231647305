html {
  font-family: sans-serif;
  font-size: calc(9.6px + 2vw);  /*16 > 22px*/
}

.large-scale {
  /* 40px > 70px */
  font-size: calc(5rem - 40px);
}

.mid-scale {
  /* 14px > 20px */
  font-size: calc(1rem - 2px);
}

.small-scale {
  /* 12px > 14px */
  font-size: calc((1.75rem + 3px) / 3);
}

@media all and (min-width: 600px){

  html {
    /* cap at 22px */
    font-size: 22px;
  }

}

.sdc-climate-counter__gradient {
  background: linear-gradient(80.26deg, #288B96 32.35%, #0A63B3 85.46%);
  color: #FFFFFF;
  height: 100vh;
  position: relative;
}

.sdc-climate-counter__contents  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width:  26.8125rem;
  width: 100%;
  height: 10.5625rem;
  margin: 0 auto;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.sdc-climate-counter__heading {
  position: static;
  width: 100%;
  height: 1.3125rem;
  left: 0px;
  top: 0px;
  font-family: 'Sky Text';
  text-align: center;
  margin-block-end: 0rem;
}

.sdc-climate-counter__countdown {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

.sdc-climate-counter__link {
  text-align: center;
}

.sdc-climate-counter__link > a {
  color: #FFFFFF;
}

.sdc-climate-counter__glass-effect {
  position: absolute;
  width: 100%;
  height: 5.5rem;
  top: -5px;
  left: 0px;

  background: linear-gradient(347.32deg, rgba(255, 255, 255, 0.25) 30.64%, rgba(255, 255, 255, 0) 78.74%);
  mix-blend-mode: overlay;
  opacity: 0.6;
  transform: skewY(-2.5deg);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}