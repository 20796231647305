.sdc-climate-countdown__value {
  width: 1em;
  display: block;
  font-family: 'Sky Headline';
  text-align: center;
}

.sdc-climate-countdown__unit {
  display: block;
  font-family: 'Sky Headline';
  text-align: center;
}
