@font-face {
  font-family: "Sky Text";
  font-display: fallback;
  src:  url("//static.skyassets.com/fonts/sky-regular.woff2") format("woff2"),
        url("//static.skyassets.com/fonts/sky-regular.woff") format("woff");
}

@font-face {
  font-family: 'Sky Headline';
  src:  url("./fonts/SkyHeadline_W_SBd.woff2") format('woff2'),
        url("./fonts/SkyHeadline_W_SBd.woff") format('woff'),
        url("./fonts/SkyHeadline-SemiBold.ttf") format('ttf');
}
